import { useCallback, useContext } from 'react';
import {
  getCookieConsentValue,
  resetCookieConsentValue,
} from 'react-cookie-consent';
import { AppContext } from './context';

export const cookieName = 'mhy.consent';

export const getIsCookiesAccepted = () => getCookieConsentValue(cookieName);

export const useConsenting = () => {
  const [{ consenting }, setState] = useContext(AppContext);

  const revokeConsenting = useCallback(() => {
    resetCookieConsentValue(cookieName);
    setState((s) => ({ ...s, consenting: false }));
  }, [setState]);

  return {
    consenting: consenting || getCookieConsentValue(cookieName),
    revokeConsenting,
    setConsenting: () => {
      setState((s) => ({ ...s, consenting: true }));
    },
  };
};
