const Teaser = {
  variants: {
    lg: {
      p: {
        fontSize: 'lg',
      },
    },
    white: {
      color: 'white',
    },
    membership: {
      color: 'white',
      h2: {
        color: 'white',
      },
    },
    info: {
      color: 'black',
      textAlign: 'center',
      h2: {
        color: 'white',
        whiteSpace: 'nowrap',
      },
    },
  },
};

export default Teaser;
