import { Flex } from '@chakra-ui/react';
import Link from './Link';

const FooterLinkList = ({ footerLinks }) => (
  <Flex
    gridGap={5}
    flex={['0 0 100%', null, '0 0 auto']}
    py={[8, null, 0]}
    mr="auto"
  >
    {footerLinks?.map((link) => (
      <Link href={link.url} key={link.url}>
        {link.title}
      </Link>
    ))}
  </Flex>
);

export default FooterLinkList;
