import { Box, Container, Flex, List } from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { safariOnlyCss } from '../../styles/theme';
import SiteSelector from '../SiteSelector';
import { useIsMobileHeader } from './utils';

const HeaderLinks = dynamic(() => import('./HeaderLinks'));

const HeaderGlobal = () => {
  const isMobileHeader = useIsMobileHeader();

  return (
    <Box
      bg="secondaryBackgroundColor"
      px={5}
      data-test-id="nav-header-site-selector"
    >
      <Flex
        as={Container}
        maxW="container.xl"
        py={2}
        justifyContent="space-between"
        alignItems="center"
        variant="noSpacing"
      >
        <SiteSelector />
        {isMobileHeader ? null : (
          <Flex
            as={List}
            alignItems="center"
            gridGap="4"
            sx={safariOnlyCss({ '> *:not(:last-child)': { mr: 4 } })}
            position="relative"
          >
            <HeaderLinks />
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default HeaderGlobal;
