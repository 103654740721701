import { FocusEventHandler, ReactNode, RefObject } from 'react';
import { useRouter } from 'next/router';
import { Text, Link as ChakraLink } from '@chakra-ui/react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { MhyChakraProps, SiteLocale } from '../types';
import { useAppdata, useResolveUrl } from '../lib/hooks.context';

export interface LinkProps {
  id?: string;
  href?: NextLinkProps['href'];
  ref?: RefObject<any>;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  locale?: SiteLocale | false;
  target?: '_blank' | '_self';
  onClick?: (e: any) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onMouseMove?: () => void;
  onBlur?: FocusEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement | HTMLParagraphElement>;
  title?: string;
  rel?: string;
  prefetch?: boolean;
}

// pretty loose way to check this, but it should do the trick. Asset urls take protocol relatively, so check for //
const isFullUrl = (s: string): boolean =>
  Boolean(
    s && (/(http(s?)):\/\//i.test(s) || !s.indexOf('www.') || !s.indexOf('//')),
  );

const isSelfUrl = (s: string): boolean =>
  Boolean(s.includes('mhy.fi') || s.includes('localhost'));

// Not really needed, but maybe nice in development start
const isWpUrl = (s: string) =>
  !isFullUrl(s) && Boolean(s.includes('wordpress'));

const Link = ({
  children,
  href: someHref,
  locale,
  disabled,
  variant,
  prefetch = false,
  onClick,
  target,
  ...props
}: LinkProps & MhyChakraProps) => {
  const href = useResolveUrl(typeof someHref === 'string' ? someHref : '');
  const { sites, subsite } = useAppdata();

  const { asPath, locale: currentLocale } = useRouter();

  if (!someHref) {
    return (
      <Text as="span" {...props} {...(onClick ? { onClick } : null)}>
        {children}
      </Text>
    );
  }

  if (typeof someHref === 'object') {
    return (
      <NextLink href={someHref} locale={locale} passHref>
        <ChakraLink
          {...props}
          {...(onClick ? { onClick } : null)}
          variant={variant}
        >
          {children}
        </ChakraLink>
      </NextLink>
    );
  }

  const nextLinkProps: NextLinkProps = { locale, href, prefetch };
  const autoProps: Record<string, unknown> = {};
  if (isFullUrl(href) || isWpUrl(href)) {
    autoProps.target = '_blank';
    if (!isSelfUrl(href)) {
      nextLinkProps.prefetch = false;
      autoProps.rel = 'noopener nofollow';
    }
  } else if (!disabled) {
    // relative url, so internal link and not disabled

    if (href.startsWith('#')) {
      // anchor links
      nextLinkProps.href = `${asPath.split('#')[0]}${href}`;
      autoProps.onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (document.querySelector(href)) {
          e.preventDefault();
          document.querySelector(href)?.scrollIntoView({ behavior: 'smooth' });
        }
        if (onClick) {
          onClick(e);
        }
      };
    } else {
      // Normal internal links

      const [base] = href.split('/').filter(Boolean);
      if (base !== 'api') {
        if (
          subsite &&
          base !== subsite &&
          (!locale || locale === currentLocale)
        ) {
          // on a subsite, but links to another internal url, which must be on another blog unless it's language change
          autoProps.target = '_blank';
        } else if (!subsite && sites.find(({ name }) => name === base)) {
          // on main site and links to another blog
          autoProps.target = '_blank';
        } else if (
          asPath.split('#')[0] === href &&
          (!locale || locale === currentLocale)
        ) {
          // Doesn't consider query params for a reason
          autoProps['aria-current'] = 'page';
        }
      }
    }
  } else {
    // disabled link
    nextLinkProps.href = '#';
  }

  return (
    <NextLink {...nextLinkProps} passHref>
      <ChakraLink
        {...(onClick ? { onClick } : null)}
        {...autoProps}
        {...props}
        {...(target ? { target } : null)}
        variant={variant}
      >
        {children}
      </ChakraLink>
    </NextLink>
  );
};

export default Link;
