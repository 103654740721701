import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  Box,
  Flex,
  ListItem,
  List,
  useDisclosure,
  Collapse,
  Button,
  Accordion,
  As,
  ListItemProps,
  ChakraProps,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { NavPost } from '../../types';
import Search from './Search';
import Logo from '../Logo';
import { CloseIcon, MenuIcon } from '../../icons';
import {
  mobileNavBreakpointKey,
  navNextBreakpointKey,
  useIsMobileHeader,
} from './utils';
import { useAppdata, useBlog } from '../../lib/hooks.context';
import { safariOnlyCss } from '../../styles/theme';

const HeaderLinks = dynamic(() => import('./HeaderLinks'));

const DesktopMenuItem = dynamic(() => import('./MenuItems/DesktopItem'));
const MobileMenuItem = dynamic(() => import('./MenuItems/MobileItem'));
const LinkItem = dynamic(() => import('./MenuItems/LinkItem'));

const NavListItem = (props: ListItemProps) => (
  <ListItem
    {...props}
    _notLast={{
      base: { marginBottom: 4 },
      [mobileNavBreakpointKey]: { marginBottom: 0 },
    }}
  />
);

const MhyMenuItem = ({
  isMobile,
  defaultIndexes,
  ...item
}: NavPost & {
  isMobile: boolean;
  /* eslint-disable react/no-unused-prop-types */
  onNavigate: () => void;
  defaultIndexes?: number[];
}) => {
  // menu items are always just links in case they dont have children
  if (!item.children) {
    return <LinkItem {...item} as={NavListItem} />;
  }

  if (!isMobile) {
    return (
      <NavListItem>
        <DesktopMenuItem {...item} />
      </NavListItem>
    );
  }

  return <MobileMenuItem {...item} {...{ defaultIndexes }} as={NavListItem} />;
};

/* NOTE: I couldn't figure out another way without compromising the animation/transition
 * Collapse takes only 'style' and that can't handle @media and probably not theme props either

 * TODO: instead of top: pixel value; use Nav's position with ref to calculate the top value. It is dynamic based on scrolling.
 */

const MobileMenu = styled(Collapse)(
  ({ fromTop }: { fromTop: number }) => `
  flex: 1 1 auto;
  overflow: visible!important;

  @media (max-width: 61.999em) {
    position: fixed;
    top: ${fromTop}px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0 3rem;
    background: white;
    z-index: 1;
    overflow-y: auto !important;
  }
`,
);

const mobileAccordionProps = {
  allowToggle: true,
  allowMultiple: true,
  id: 'main-mobile-menu',
  as: 'ul' as As,
  width: '100%',
  variant: 'nav',
};

// Resolve the default state of accordion based on its items and the current path of the website
const resolveDefaultAccordionIndexes = ({
  site,
  entry,
  children,
}: Pick<NavPost, 'children'> & {
  site: string;
  entry?: string[];
}): number[] => {
  if (!entry) {
    return [];
  }
  const path = `${site ? `/${site}` : ''}/${entry.join('/')}`;

  // Basically "go deep until you find the match if such exists, and report back with the indexes you went through to find the match"
  const findIndexesForPathMatch = (
    items: NavPost[] | null | undefined,
    gatheredIndexes: number[] = [],
  ): false | number[] => {
    if (!items || !Array.isArray(items)) {
      return false;
    }
    let i = 0; // Index for the while
    let index = -1; // Real index of accordion. Skips non-accordion items
    const isFinalLevel = !items?.some(({ children: c }) => c); // Final level will have no children and it is to be counted in indexes

    while (i < items.length) {
      const item = items[i];

      if (item.children || isFinalLevel) {
        index += 1;
      } else if (item.url === path) {
        // else skips all solo items from the index, which are not of type AccordionItem
        return false;
      }

      if (item.url === path) {
        return [...gatheredIndexes, index];
      }

      if (item.children?.length) {
        const indexes = findIndexesForPathMatch(item.children, [
          ...gatheredIndexes,
          index,
        ]);

        if (indexes && indexes?.length) {
          return indexes;
        }
      }

      i += 1;
    }

    return false;
  };
  return findIndexesForPathMatch(children) || [];
};

const calculateHeaderDistanceFromTop = ({
  parentElement: header,
}: HTMLDivElement): number =>
  header ? header.getBoundingClientRect().top + header.offsetHeight : 0;

interface State {
  fromTop: number;
  defaultAccordionIndexes: number[];
}

const Nav = (props: ChakraProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { navigations } = useAppdata();
  const nav = navigations?.main_navigation;
  const {
    query: { entry },
  } = useRouter();
  const site = useBlog(false);
  const resolvedEntry = entry
    ? (entry as string[]).filter((slug) => slug !== site)
    : undefined;
  const isMobile = useIsMobileHeader();

  const [
    {
      fromTop,
      defaultAccordionIndexes: [defaultAccordionIndex, ...defaultSubIndexes],
    },
    setState,
  ] = useState<State>(() => ({
    fromTop: 106,
    defaultAccordionIndexes:
      isMobile && resolvedEntry
        ? resolveDefaultAccordionIndexes({
            site,
            entry: resolvedEntry,
            children: nav,
          })
        : [],
  }));
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMobile) {
      // Default open indexes for mobile accordions
      const reResolvedEntry = entry
        ? (entry as string[]).filter((slug) => slug !== site)
        : ['/'];
      if (reResolvedEntry) {
        setState((s) => ({
          ...s,
          defaultAccordionIndexes: resolveDefaultAccordionIndexes({
            site,
            entry: reResolvedEntry,
            children: nav,
          }),
        }));
      }
    }
  }, [isMobile, site, entry, nav]);

  const NavList = <T extends unknown>({
    mobile,
    ...rest
  }: T & { mobile: boolean }) =>
    mobile ? <Accordion {...rest} /> : <List {...rest} />;

  const isMobileMenuOpen = isOpen && isMobile;

  useEffect(() => {
    document?.body?.classList[isMobileMenuOpen ? 'add' : 'remove'](
      'mobile-menu-open',
    );

    if (isMobileMenuOpen && navRef?.current) {
      const calculated = calculateHeaderDistanceFromTop(navRef?.current);
      if (calculated) {
        setState((s) => ({ ...s, fromTop: calculated }));
      }
    }

    return () => {
      document?.body?.classList.remove('mobile-menu-open');
    };
  }, [isMobileMenuOpen]);

  let accordionIndex = -1;

  return (
    <Flex
      as="nav"
      alignItems="center"
      justifyContent="space-between"
      ref={navRef}
      {...props}
    >
      <Box flex="2 0 auto">
        <Logo />
      </Box>
      <MobileMenu in={isOpen || !isMobile} fromTop={fromTop} animateOpacity>
        <NavList
          mobile={isMobile}
          display={{
            base: isOpen ? 'flex' : 'none',
            [mobileNavBreakpointKey]: 'flex',
          }}
          flexDirection={{
            base: 'column',
            [mobileNavBreakpointKey]: 'row',
          }}
          justifyContent={{
            [mobileNavBreakpointKey]: 'flex-start',
          }}
          gridGap={{
            [mobileNavBreakpointKey]: 4,
            [navNextBreakpointKey]: 8,
          }}
          alignItems={{ [mobileNavBreakpointKey]: 'center' }}
          _first={{ [mobileNavBreakpointKey]: { marginLeft: 'auto' } }}
          sx={safariOnlyCss(
            { '> *:not(:last-child):not(:first-child)': { ml: 4 } },
            mobileNavBreakpointKey,
          )}
          {...(typeof defaultAccordionIndex === 'number' && isMobile
            ? { defaultIndex: [defaultAccordionIndex] }
            : null)}
          {...(isMobile ? { ...mobileAccordionProps, as: List } : null)}
        >
          {nav?.map((item) => {
            if (item.children) {
              accordionIndex += 1;
            }

            return (
              <MhyMenuItem
                {...item}
                key={item.ID}
                isMobile={isMobile}
                onNavigate={onClose}
                defaultIndexes={
                  typeof defaultAccordionIndex === 'number' &&
                  accordionIndex === defaultAccordionIndex
                    ? defaultSubIndexes
                    : undefined
                }
              />
            );
          })}
          <NavListItem
            flex={{ [mobileNavBreakpointKey]: '.3 1 150px' }}
            px={{ base: 4, [mobileNavBreakpointKey]: 0 }}
            pt={{ base: 4, [mobileNavBreakpointKey]: 0 }}
            m={{ [mobileNavBreakpointKey]: 'auto 0 auto auto' }}
          >
            <Search onNavigate={onClose} />
          </NavListItem>
          {isMobile && (
            <HeaderLinks px="4" as={NavListItem} pt="4" onNavigate={onClose} />
          )}
        </NavList>
      </MobileMenu>
      <Button
        display={{ base: 'inline-flex', [mobileNavBreakpointKey]: 'none' }}
        mr={isOpen ? 1 : -1}
        onClick={onToggle}
        variant="transparent"
        justifyContent="flex-end"
      >
        {isOpen ? <CloseIcon /> : <MenuIcon size="32" />}
      </Button>
    </Flex>
  );
};
export default Nav;
