import { ReactNode } from 'react';
import { Location } from '../types';

export const arrayOrStringToArray = (value: string | string[]): string[] =>
  (Array.isArray(value) ? value : value?.split(','))?.filter(Boolean) || [];

export const isArrayOfStrings = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === 'string');

/**
 * is_email function from WordPress
 *    written in Javascript
 *
 * by Louy Alakkad <me@l0uy.com>
 *
 * Verifies that an email is valid.
 *
 * Does not grok i18n domains. Not RFC compliant.
 *
 * @param string email Email address to verify.
 * @return string|bool Either false or the valid email address.
 */

function str_trim(str: string, regex: string): string {
  const chr = regex.replace(/[-[\]/{}()*+?.\\^$|:!,=]/g, '\\$&');
  return str
    .replace(new RegExp(`/^[${chr}]*/`), '')
    .replace(new RegExp(`/[${chr}]*$/`), '');
}

export const mhyDateSort = (a?: string | null, b?: string | null) => {
  if (!a || !b) {
    return 0;
  }
  const dmya = a.split('.');
  const dmyb = b.split('.');
  const da = new Date(
    parseInt(dmya[2], 10),
    parseInt(dmya[1], 10) - 1,
    parseInt(dmya[0], 10),
  );
  const db = new Date(
    parseInt(dmyb[2], 10),
    parseInt(dmyb[1], 10) - 1,
    parseInt(dmyb[0], 10),
  );
  if (da === db) {
    return 0;
  }
  return da < db ? 1 : -1;
};

export const isEmail = (email: string): boolean | string => {
  // Test for the minimum length the email can be
  if (email.length < 3) {
    return false;
  }

  // Test for a single @ character after the first position
  if (
    email.indexOf('@') === -1 ||
    email.indexOf('@') !== email.lastIndexOf('@')
  ) {
    return false;
  }

  // Split out the local and domain parts
  const parts = email.split('@', 2);
  const local = parts[0];
  const domain = parts[1];

  // LOCAL PART
  // Test for invalid characters
  if (!/^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+$/.test(local)) {
    return false;
  }

  // DOMAIN PART
  // Test for sequences of periods
  if (/\.{2,}/.test(domain)) {
    return false;
  }

  // Test for leading and trailing periods and whitespace
  if (str_trim(domain, ' \t\n\r\0\x0B.') !== domain) {
    return false;
  }

  // Split the domain into subs
  const subs = domain.split('.');

  // Assume the domain will have at least two subs
  if (subs.length < 2) {
    return false;
  }

  // Loop through each sub
  // eslint-disable-next-line
  subs.forEach((sub) => {
    // Test for leading and trailing hyphens and whitespace
    if (str_trim(sub, ' \t\n\r\0\x0B-') !== sub) {
      return false;
    }

    // Test for invalid characters
    if (!/^[a-z0-9-]+$/i.test(sub)) {
      return false;
    }
  });

  // Congratulations your email made it!
  return !!email;
};

const sanitizeCmsStrings = (s: string): string => {
  if (s.indexOf('&nbsp;') > -1) {
    return s.replace(/&nbsp;/g, ' ').trimRight();
  }
  const re = new RegExp(String.fromCharCode(160), 'g');

  if (re.test(s)) {
    return s.replace(re, ' ').trimRight();
  }

  return s;
};

/*
 * WP can output things like &shy; but they can't be rendered as children because then they're just strings as are.
 * Basically this could be called 'getChildrenWithHtmlEntities' but I feel it's too long
 *
 * Usage: <MyTextComponent {...getTextChildren(textPropsWithChildren)} />
 */

export const getTextChildren = ({
  children,
  ...props
}: {
  children?: null | string | ReactNode;
}) => {
  if (typeof children === 'string') {
    // children sanitized from possible &nbsp;
    const safeChildren = sanitizeCmsStrings(children);
    // pattern to check for left out html entities
    const entitiesPattern = /&[a-z]+;/g;

    // if there's unsanitized patterns left, render as html. Such pattern can be &shy; eg.
    if (entitiesPattern.test(children)) {
      return {
        dangerouslySetInnerHTML: {
          __html: safeChildren,
        },
        children: null,
      };
    }
    return {
      ...props,
      children: safeChildren,
    };
  }
  return { children, ...props };
};

export const formatPhoneNumber = (phone: string) => {
  const number = phone.startsWith('+358')
    ? `0${phone.replace('+358', '')}`
    : phone;
  const parts = number.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (!parts) {
    return phone;
  }
  const [, a, b, c] = parts;
  return `${a} ${b} ${c}`;
};

export const formatLocation = (location: Location) => {
  const lastIndex = location.address.lastIndexOf(',');
  const address =
    lastIndex !== -1
      ? location?.address?.substring(0, lastIndex)
      : location?.address;
  return location?.city || address;
};

export const getCityOrState = (location: Location) =>
  location?.city || location?.state;

export const formatAddressFromLocation = (location: Location) => {
  // Sometimes city is empty and state is used instead
  const address = [
    `${location.street_name}
    ${location.street_number ? location.street_number : ''}`,
    <br key={`${location.place_id}-br1`} />,
    `${location.post_code} ${getCityOrState(location)}`,
  ];
  // If name is not street address it's probably a named place.
  // Let's add it as first
  if (
    location.street_number &&
    location.name !== `${location.street_name} ${location.street_number}`
  ) {
    address.unshift(location.name, <br key={`${location.place_id}-br2`} />);
  }
  return address;
};

// NOTE: un-used, but commiting as commented because I constantly keep needing it and then not needing so I keep re-searching for it
// export const slugify = (oStr: string): string => {
//   let str = oStr.trim().toLowerCase();

//   // remove accents, swap ñ for n, etc
//   const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
//   const to = "aaaaaaeeeeiiiioooouuuunc------";

//   for (let i = 0, l = from.length; i < l; i+=1) {
//     str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
//   }

//   return str
//     .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
//     .replace(/\s+/g, "-") // collapse whitespace and replace by -
//     .replace(/-+/g, "-") // collapse dashes
//     .replace(/^-+/, "") // trim - from start of text
//     .replace(/-+$/, "") // trim - from end of text
// }

// NOTE: WP may or may not have multiple loggedin cookies and hence the filter instead of find
export const getWpAuthCookie = (cookies?: string) =>
  cookies
    ?.split('; ')
    .filter((cookie: string) => cookie.includes('wordpress_logged_in'))
    .join('; ');

const localeMap = {
  fi: 'fi-FI',
  sv: 'sv-SE',
};

export const createLocale = (lang: string): string => localeMap[lang] || lang;
