const Container = {
  baseStyle: {
    px: 5,
  },
  variants: {
    main: {
      px: 0,
      mb: [5, null, 10],
    },
    noSpacing: {
      px: 0,
    },
  },
};

export default Container;
