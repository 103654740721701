import fs from 'fs';
import { promisify } from 'util';
import path from 'path';

export const readFile = () => promisify(fs.readFile);
export const writeFile = () => promisify(fs.writeFile);

const CACHE_FILE = path.resolve('.data-cache');
const useCache =
  process.env.RUNNING_BUILD ||
  (process.env.NODE_ENV === 'development' &&
    !process.env.NEXT_PUBLIC_DISABLE_DEV_CACHE);
const emptyCache = '{}';

const getCacheData = async () =>
  JSON.parse((await readFile()(`${CACHE_FILE}`, 'utf8')) || emptyCache);

export const getData = async <P extends unknown>(): Promise<P | null> => {
  if (useCache && typeof document === 'undefined') {
    try {
      const data = await getCacheData();
      return data;
    } catch (_) {
      /* not fatal */
    }
  }

  return null;
};

export const saveData = async (data: Record<string, unknown>) => {
  if (useCache && typeof document === 'undefined') {
    const cacheFile = `${CACHE_FILE}`; // ${isPreview ? '_previews' : ''}`
    const saved = await getCacheData();
    writeFile()(cacheFile, JSON.stringify({ ...saved, ...data }), 'utf8').catch(
      () => {
        /* this is fine */
      },
    );
  }
};
