// https://phosphoricons.com
import { Icon, IconProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';

interface MapIconProps extends IconProps {
  color?: string;
  size?: string;
}

export const CloseIcon = ({ color, size = '4', ...rest }: MapIconProps) => (
  // NOTE: This is actually copy pasted from Chakra-icons. Designs are missing it.
  <Icon viewBox="0 0 24 24" h={size} w={size} {...rest} color={color}>
    <path
      fill="currentColor"
      d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
    />
  </Icon>
);

const QuoteIcon = ({ color, size = '11', ...rest }: MapIconProps) => (
  <Icon viewBox="0 0 11 11" h={size} w={size} {...rest} color={color}>
    <path
      d="m6.46 10.6v-4.28l2.12-5.760002h2.36l-1.8 5.360002h1.8v4.68zm-5.8 0v-4.28l2.16-5.760002h2.32l-1.8 5.360002h1.8v4.68z"
      fill="currentColor"
    />
  </Icon>
);

const BirchIcon = ({ size = '11px' }) => (
  <Box w={`${size}px`} h={`${size}px`}>
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m322.4 111.4c-3.9 0-7.8.3-11.6.9 1.2-4.8 1.8-9.8 1.8-14.9 0-34.7-28.1-62.9-62.9-62.9s-62.9 28.1-62.9 62.9.6 10.1 1.8 14.9c-3.6-.5-7.3-.8-11.1-.8-42.6 0-77.2 34.6-77.2 77.2s34.6 77.2 77.2 77.2h144.8c42.6 0 77.2-34.6 77.2-77.2s-34.6-77.2-77.2-77.2z"
        fill="#fff"
      />
      <path
        d="m322.4 269.3h-144.8c-44.5 0-80.7-36.2-80.7-80.7s36.2-80.7 80.7-80.7 4.4 0 6.7.3c-.6-3.6-.9-7.2-.9-10.9 0-36.6 29.8-66.3 66.3-66.3s66.3 29.8 66.3 66.3-.3 7.3-.9 10.9c2.4-.2 4.8-.3 7.2-.3 44.5 0 80.7 36.2 80.7 80.7s-36.2 80.7-80.7 80.7zm-144.8-154.4c-40.6 0-73.7 33.1-73.7 73.7s33.1 73.7 73.7 73.7h144.8c40.6 0 73.7-33.1 73.7-73.7s-33.1-73.7-73.7-73.7-7.4.3-11.1.8l-5.1.8 1.2-5c1.1-4.6 1.7-9.4 1.7-14.1 0-32.7-26.6-59.4-59.4-59.4s-59.4 26.6-59.4 59.4.6 9.4 1.7 14.1l1.2 5-5.1-.7c-3.5-.5-7.1-.8-10.6-.8z"
        fill="#074"
      />
      <path
        d="m263 56.2s-.2-8-.3-8c-34.7 0-62.9 13.6-62.9 57.5s.6 12.8 1.8 17.5c-3.6-.5-7.3-.8-11.1-.8-42.6 0-74.5 20.9-74.5 74.5s31.9 53.2 74.5 53.2h72.4v-193.9z"
        fill="#5bb250"
      />
      <path
        d="m159.5 462.9c-2.5-.5-2.1-4.2.4-4.2h163.7c46.1 0-32 12.4-76.9 12.4s-65.4-3.7-87.3-8.1z"
        fill="#074"
      />
      <path
        d="m330.7 196.5-8.8-11.1-47.2 44.9-4.7-74.2h-39.2l-4.7 139.9-53.4-62.7-13.4 11.1 66.8 83.1v133.9h48.6v-199.6z"
        fill="#fff"
      />
      <g fill="#074">
        <path d="m278.2 464.8h-55.5v-136.1l-68.2-84.8 18.7-15.5 49.8 58.4 4.5-134.2h45.9l4.4 70 44.6-42.4 12.9 16.3-57.1 66.5v201.7zm-48.6-6.9h41.6v-197.3l.8-1 54.2-63.1-4.6-5.9-49.9 47.5-4.9-78.4h-32.6l-4.8 145.5-57-66.9-8.1 6.7 65.4 81.3v131.6z" />
        <path d="m250 418.8h24.7v7h-24.7z" />
        <path d="m226.1 378.5h23.9v7h-23.9z" />
        <path d="m243.9 346.4h18.5v7h-18.5z" />
        <path d="m226.1 292.5h23.9v7h-23.9z" />
        <path d="m240.4 245.3h32v7h-32z" />
        <path d="m232.3 196.6h17.1v7h-17.1z" />
        <path d="m247.8 170.9h17.1v7h-17.1z" />
      </g>
    </svg>
  </Box>
);
const PineIcon = ({ size = '11px' }) => (
  <Box w={`${size}px`} h={`${size}px`}>
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m147.2 112.6c-11.9 0-21.6 9.7-21.6 21.6 0 11.9 9.7 21.6 21.6 21.6h229.5c11.9 0 21.6-9.7 21.6-21.6 0-11.9-7.8-25.5-20.3-28.1l-37.6-10s17.7-20.7 17.7-36-13.8-33.7-37.5-33.7-33.5 10.9-42.3 19.7l-2-9.4c-4.8-10.9-15.6-28.1-38-26.3-16.8 1.3-32.8 10.2-36.1 26.7s-.7 13.8 1.4 19.9c-6.8-2.7-14.7-4.3-23.1-4.3-25 0-39.7 10.2-41.4 30.3-1.2 14.3 17.8 29 17.8 29l-9.7.7z"
        fill="#fff"
      />
      <path
        d="m376.7 159.8h-229.5c-14.1 0-25.6-11.5-25.6-25.6s11.4-25.6 25.5-25.6h.2c-5.9-6.2-13-15.9-12.2-25.9 1.8-21.9 17.9-34 45.4-34s11.9.8 17.5 2.2c-.7-4.8-.6-9.7.4-14.6 3.3-16.5 18.9-28.3 39.7-30 18.5-1.5 33.1 8.4 42.1 28.7l.3.8.5 2.4c12.4-10.5 25.7-15.9 39.8-15.9 27 0 41.5 19.5 41.5 37.8s-9.3 26.3-14.8 33.7l31.6 8.4c14.5 3 23.3 18.6 23.3 32s-11.5 25.6-25.6 25.6zm-229.5-43.2c-9.7 0-17.5 7.9-17.5 17.5s7.9 17.5 17.5 17.5h229.5c9.7 0 17.5-7.9 17.5-17.5s-6.5-21.9-17-24.1l-44.1-11.7 4.2-4.9c4.7-5.4 16.8-22 16.8-33.4s-11.7-29.7-33.4-29.7-27.2 6.2-39.4 18.5l-5.3 5.3-3.4-16.3c-7.6-16.8-19-24.7-33.8-23.5-13.6 1.1-29.3 7.8-32.4 23.5-1.2 6-.8 11.9 1.2 17.8l2.9 8.4-8.2-3.3c-6.6-2.6-14-4-21.7-4-23.3 0-35.8 8.9-37.3 26.6-.8 9.9 11.4 21.7 16.2 25.5l8.4 6.5-20.6 1.4z"
        fill="#074"
      />
      <path
        d="m296.5 466.5h-73.3v-147.6l-96.3-67 42.7-9.5 53.8 37.4 2.6-128.2h67.7l2.6 91.7 32.8-22.8 42.7 9.5-75.2 52.3v184.2zm-65.2-8.1h57.1v-180.3l63.5-44.2-21.1-4.7-42.2 29.3-2.8-98.8h-51.8l-2.8 135.4-63.3-44-21.1 4.7 84.6 58.8v143.8z"
        fill="#074"
      />
      <path
        d="m426.9 190.2h-37.2c-.3 0-.7 0-1 0 .3-1.1.5-2.3.5-3.6 0-8.1-7.9-14.7-17.5-14.7s-17.5 6.6-17.5 14.7.6 7 .6 7c-4.2 0-7.2 0-12.3 0-21.4 0-38.8 1.7-38.8 14.7s12.1 12 18.8 12.6c-1 2.2-1.7 4.6-1.7 7.1 0 9.1 7.4 16.6 16.6 16.6h50.5c9.1 0 16.6-7.4 16.6-16.6 0-5.7-5.2-13.7-5.2-13.7h27.7c6.7 0 12.1-5.4 12.1-12.1s-5.4-12.1-12.1-12.1z"
        fill="#fff"
      />
      <path
        d="m387.9 248.6h-50.5c-11.4 0-20.6-9.3-20.6-20.6s.1-2.3.3-3.4c-7.1-.7-17.5-3.1-17.5-16.2 0-18.7 26.8-18.7 42.9-18.7s4.8 0 6.8 0h.9c0-1-.1-2-.1-2.9 0-10.4 9.7-18.8 21.6-18.8s21.2 8.1 21.6 18.2h33.6c8.9 0 16.1 7.2 16.1 16.1s-7.2 16.1-16.1 16.1h-20.8c1.3 2.9 2.5 6.4 2.5 9.6 0 11.4-9.3 20.6-20.6 20.6zm-45.4-50.9c-25 0-34.7 3-34.7 10.6s3.8 7.8 12.8 8.4c.8 0 1.6.1 2.4.2l5.8.5-2.5 5.3c-.8 1.7-1.3 3.5-1.3 5.3 0 6.9 5.6 12.5 12.5 12.5h50.5c6.9 0 12.5-5.6 12.5-12.5s-2.8-8.7-4.6-11.4l-4.1-6.3h35.2c4.4 0 8-3.6 8-8s-3.6-8-8-8h-37.2c-.3 0-.5 0-.7 0l-5.9.5 1.7-5.7c.3-.8.4-1.6.4-2.4 0-5.9-6-10.7-13.5-10.7s-13.5 4.8-13.5 10.7.4 5.3.6 6.4l.7 4.7h-4.7c-2 0-3.7 0-5.5 0-2 0-4.1 0-6.8 0z"
        fill="#074"
      />
      <path
        d="m194.3 229.2c.2-1.3.3-2.5.3-3.8 0-13.4-10.9-24.3-24.3-24.3s-20.6 9.4-20.6 9.4c0-10.3-9.2-18.7-20.5-18.7s-20.5 8.4-20.5 18.7c0 0-12.9-3.8-23.7-3.8s-23.8 9.9-23.8 22.1 7.7 22.1 23.8 22.1c0 0-3 20.9 15.6 26.9 23.3 7.5 35.8-13.8 35.8-13.8 6.6 4.1 11.9 5.9 19.9 5.9s19.4-6.9 20.7-15.7c18 0 39.2 1.5 39.2-12.1s-10.4-12.4-21.9-12.8z"
        fill="#fff"
      />
      <path
        d="m110.1 283.4c-3.4 0-7-.5-10.9-1.8-14.7-4.7-18.5-17.9-18.6-26.9-14.5-1.6-23.6-11.4-23.6-26s13-26.2 27.9-26.2 15.3 1.6 20.3 2.8c2.5-10.1 12.3-17.7 24-17.7s20.2 6.6 23.4 15.8c4.7-2.9 11.6-6.5 17.8-6.5 15.6 0 28.4 12.7 28.4 28.4 9.5.6 21.5 3 21.5 16.7s-1.3 7.2-3.9 9.7c-6.7 6.7-20.8 6.6-34.4 6.5-.6 0-1.2 0-1.8 0-3.1 9.2-12.8 15.7-23.9 15.7s-12.6-1.3-18.7-4.5c-5 6.4-14.6 14-27.4 14zm-25.2-72.7c-9.1 0-19.8 7.9-19.8 18.1s7.4 18.1 19.8 18.1h4.7l-.7 4.7c0 .7-2.3 17.6 12.8 22.4 19.9 6.4 30.6-11.2 31.1-12l2.1-3.6 3.5 2.2c6.3 3.9 10.8 5.3 17.7 5.3s15.7-5.3 16.7-12.2l.5-3.5h3.5 5c11.4 0 24.3.2 28.7-4.1.6-.6 1.5-1.7 1.5-4 0-6.3-3.9-8.2-18-8.8l-4.6-.2.7-4.5c.2-1.2.3-2.2.3-3.2 0-11.2-9.1-20.2-20.2-20.2s-15.1 6.1-18.1 8.5l-6.6 5.2v-8.4c0-8.1-7.4-14.6-16.5-14.6s-16.5 6.6-16.5 14.6v5.5l-5.2-1.6c-.1 0-12.5-3.7-22.5-3.7z"
        fill="#074"
      />
      <path
        d="m168.6 462.7c-2.6-.5-2.2-4.3.5-4.3h166.6c46.9 0-32.6 12.6-78.3 12.6s-66.6-3.8-88.8-8.3z"
        fill="#074"
      />
      <g fill="#5bb250">
        <path d="m149.7 145c-7.2 0-10.4-5.4-10.4-12.6s5.9-10.4 13.1-10.4h-2.5l34.6-2.2-18.5-14.1c-4.8-3.8-13.7-11.6-13.7-21s1.1-19.6 31.1-19.6 11 1.5 16.8 3.8l20.4 6.2-4.5-18c-1.7-4.9-3.2-12.4-2.2-17.4 2.6-12.9 12.9-16.5 24.4-17.5h2.4c3.1 0 9.1 1.6 9.1 1.6v121.2z" />
        <path d="m241.1 169.1h11.4v281.9h-11.4z" />
        <path d="m131.8 201.9s-3.6-1.1-7.6.6c-8.3 3.6-5.5 11.8-5.5 20.6 0 0-20.1-4.4-28.5-5.5s-18.2 2.3-18.2 12.6 12.8 11.4 26.5 11.4c0 0-9 20 6.8 24.6s19-4.2 26.5-12.4z" />
      </g>
    </svg>
  </Box>
);
const SpruceIcon = ({ size = '11px' }) => (
  <Box w={`${size}px`} h={`${size}px`}>
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m307.9 281.8-4.9-7h61.5l-70.5-101-.8-1.1h51.6l-94.8-132.4-94.8 132.3h51.6l-71.3 102.2h61.5l-76.2 109.1h258.3l-71.3-102.1zm-57.9 96h-113.9l77.2-109.3h-64.7l75.8-103.9-54.6.6 80.4-114.3v326.8z"
        fill="none"
      />
      <path d="m241.4 392.9v70.5h17.2v-70.5h-8.6z" fill="none" />
      <path
        d="m224.3 164.6-75.8 103.9h64.7l-77.1 109.3h113.9v-326.8l-80.4 114.2z"
        fill="#5bb250"
      />
      <path
        d="m232.4 422.7v44l-90.1.3-.8.7-1 .9 1.1 1.5.4.5h.6c9 1.6 18 2.2 26.9 3.2s0 0 0 0c8.9.7 17.9 1.4 26.8 1.8 9 .6 17.9.7 26.8 1.1s26.8.4 26.8.4l26.8-.3c8.9-.4 17.8-.5 26.8-1.1s0 0 0 0c8.9-.4 17.9-1.1 26.8-1.8s0 0 0 0c8.9-1.1 17.8-1.7 26.9-3.1s.5 0 .5 0l.7-.8.9-1-1.3-1.3-.4-.4-90.2-.3v-73.8h128.7l-74.9-109.1h57.8l-23.8-37.7-45-64.5h51.9l-112.1-156.9-112.2 156.6h51.9s-71.3 102.2-71.3 102.2h61.5l-76.2 109.1h128.7v20.7m26.2 49.8h-17.3v-70.5h17.3zm-8.6-79.5h-129.1l76.2-109.1h-61.5l71.3-102.2h-51.6l94.7-132.3 94.8 132.3h-51.6l.8 1.1 70.5 101h-61.5l4.9 7 71.3 102.1h-129.1z"
        fill="#074"
      />
    </svg>
  </Box>
);

export { List as MenuIcon } from '@phosphor-icons/react';

export { BirchIcon, PineIcon, QuoteIcon, SpruceIcon };

export default CloseIcon;
