const defaultHover = {
  bg: 'greenBirch',
  color: 'white',
  borderColor: 'greenBirch',
};
const defaultActive = {
  bg: 'greenSpruce',
  color: 'white',
};
const Button = {
  baseStyle: {
    bg: 'gray2',
    color: 'primaryColor',
    borderRadius: '4px',
    fontWeight: '600',
    fontSize: '.8rem',
    border: '1.5px solid',
    transition:
      'background .125s ease-in, color .125s ease-in, borderColor .1s ease-in',
    px: 4,
    py: 2,
    maxWidth: ['280px', '350px'],
    whiteSpace: ['initial', 'nowrap'],
    textAlign: 'center',
    height: 'auto',
    borderColor: 'gray2',
    _hover: {
      textDecoration: 'none',
      transitionTimingFunction: 'ease-out',
    },
    _disabled: {
      pointerEvents: 'none',
    },
  },
  sizes: {
    normal: {
      fontSize: '.8125rem',
      py: '.6875rem',
      px: '1.65rem',
    },
    large: {
      fontSize: '.875rem',
      py: '.78125rem',
      px: '1.65rem',
      lineHeight: '1.25',
      height: 'auto',
    },
    xl: {
      fontSize: '.875rem',
      p: '.9072rem 2.125rem',
    },
  },
  variants: {
    default: {
      fontSize: '.875rem',
      fontWeight: '400',
      _hover: {
        bg: 'gray4',
        borderColor: 'gray4',
        color: 'primaryColor',
      },
      _active: {
        bg: 'gray5',
        borderColor: 'gray5',
        color: 'primaryColor',
      },
      _selected: {
        bg: 'highlightColor',
        borderColor: 'highlightColor',
        color: 'white',
      },
    },
    solidGreen: {
      bg: 'greenSpruce',
      borderColor: 'greenSpruce',
      color: 'white',
      borderRadius: '500px',
      _hover: defaultHover,
      _active: defaultActive,
    },
    ghostGreen: {
      bg: 'none',
      color: 'greenSpruce',
      borderColor: 'transparent',
      borderRadius: '500px',
      outline: 'none',
      _hover: defaultHover,
      _active: defaultActive,
    },
    outlineGreen: {
      bg: 'transparent',
      color: 'greenSpruce',
      borderRadius: '500px',
      borderColor: 'greenSpruce',
      _hover: defaultHover,
      '[data-hover="true"]': defaultHover,
      _active: defaultActive,
    },
    outlineWhite: {
      bg: 'transparent',
      color: 'white',
      borderRadius: '500px',
      borderColor: 'white',
      _hover: defaultHover,
      _active: defaultActive,
    },
    transparent: {
      p: 0,
      justifyContent: 'flex-start',
      bg: 'none',
      border: 0,
      height: 'auto',
      outline: 'none',
    },
    tag: {
      bg: 'secondaryBackgroundColor',
      color: 'primaryColor',
      borderColor: 'secondaryBackgroundColor',
      borderRadius: '4px !important',
      p: '.35em !important',
      height: 'auto !important',
      minWidth: 0,
      my: 'auto',
      fontWeight: 400,
      alignItems: 'center',
      '> span, > svg': {
        ml: 2,
      },
    },
    sliderArrowEvent: {
      borderRadius: '50%',
      bg: 'highlightColor',
      borderColor: 'highlightColor',
      width: '42px',
      height: '42px',
      color: 'white',
      padding: '0',
      top: '50%',
      _hover: defaultHover,
      _active: defaultActive,
    },
    sliderArrowArticle: {
      borderRadius: '50%',
      bg: 'highlightColor',
      borderColor: 'highlightColor',
      width: '42px',
      height: '42px',
      color: 'white',
      padding: '0',
      top: [
        'calc((27/2 / 40) * ((100vw - 40px) / 3))',
        null,
        null,
        null,
        null,
        `calc((27/2 / 40) * ((var(--mhy-sizes-container-xl)) / 3))`,
      ],
      _hover: defaultHover,
      _active: defaultActive,
    },
  },
  defaultProps: {
    size: 'normal', // Without this, buttons cant be properly styled. The default, md, is broken.
    variant: 'default',
  },
};

export default Button;
