import { ComponentStyleConfig } from '@chakra-ui/react';

const Link: ComponentStyleConfig = {
  baseStyle: {
    '&[href^="mailto:"]': {
      textDecoration: 'underline',
    },
  },
  variants: {
    iconLink: {
      display: 'flex',
      gridGap: '.325em',
      alignItems: 'center',
      '> svg': {
        color: 'highlightColor',
      },
    },
  },
};

export default Link;
