import { useContext } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { AppContext, TranslationsContext, UserMetaContext } from './context';
import { Person, Site, SiteLocale, Translate } from '../types';
import { getTranslation } from './translations';

/*
 * NOTE: These hooks need to be separated, because otherwise hooks and context will create dependency cycle due to usePersistentState in context
 */

type ObjectType<T> = T extends true ? Site : T extends false ? string : never;

export const useBlog = <T extends boolean>(returnSite: T): ObjectType<T> => {
  const [{ subsite, sites }] = useContext(AppContext);
  if (!returnSite) {
    return subsite as ObjectType<T>;
  }
  return sites?.find(({ name }) => name === subsite) as ObjectType<T>;
};

export const useAppdata = () => {
  const [appData] = useContext(AppContext);
  return appData;
};

// If WP works as it should, the following hook shouldn't be needed
// NOTE: most likely unnecessary now. To be evaluated
export const useResolveUrl = (url?: string): string => {
  const { locales } = useRouter();
  const { sites } = useAppdata();
  if (!url) {
    return '';
  }

  const href = url.replace('/wordpress', '');

  // at least for now WP handles urls for translated subsites incorrectly. make sure at least FE resolves this for now
  // wp returns format like /lang/subsite/path
  // site uses /subsite/lang/path
  const [maybeLangPart, ...parts] = href.split('/').filter(Boolean);
  if (locales?.includes(maybeLangPart)) {
    const [subsitePart, ...pathParts] = parts;
    const matchedSubsite = sites
      ?.find(({ path }) => path.replace(/\//g, '') === subsitePart)
      ?.path?.replace(/\//g, '');
    if (matchedSubsite) {
      return [matchedSubsite, maybeLangPart, ...pathParts].join('/');
    }
  }
  return href;
};

export function useTranslation(): Translate {
  const { locale, defaultLocale } = useRouter();
  const translations = useContext(TranslationsContext);

  if (!locale) {
    console.error('Cant translate without locale set.');
    Sentry.captureException('Translation failed. No locale');
    return (key: string) => key;
  }

  return (key: string, args) => {
    if (!key) {
      throw new Error('can not translate without key');
    }
    return getTranslation({
      locale: locale as SiteLocale,
      defaultLocale: defaultLocale as SiteLocale,
      key,
      translations,
      args,
    });
  };
}

export const useUserMeta = () => {
  const [userMeta] = useContext(UserMetaContext);
  return userMeta;
};

export const useSelectedService = () => {
  const [{ selectedService }, setState] = useContext(UserMetaContext);

  return {
    selectedService,
    setSelectedService: (value?: typeof selectedService) => {
      setState((s) => ({
        ...s,
        selectedService: value || null,
      }));
    },
  };
};

export const useMembershipInfo = () => {
  const [{ membershipSite }, setState] = useContext(UserMetaContext);

  return {
    membershipSite,
    setMembershipSite: (value: typeof membershipSite) => {
      setState((s) => ({
        ...s,
        membershipSite: value || null,
      }));
    },
  };
};

const getForestExpert = (person: Person, t: Translate) => ({
  ...person,
  territory: [],
  roles: t('forest_expert'),
});

/*
 * Forest expert hook provides the access to sessionStoraged info.
 * Problem with this is that info doesnt exist on server side, so upon use, you need to take that into consideration and
 * save the values to eg. local state when used on components that are SSR rendered. (look up eg. ServiceOrderRequest)
 * Otherwise Next.JS throws a console.error in dev mode
 */
export const useForestExpert = () => {
  const [{ forestExpert }, setState] = useContext(UserMetaContext);
  const t = useTranslation();

  return {
    forestExpert,
    isRehydrated: typeof forestExpert !== 'undefined',
    setForestExpert: (value: typeof forestExpert) => {
      setState((s) => ({
        ...s,
        forestExpert: value ? getForestExpert(value, t) : null,
      }));
    },
  };
};
