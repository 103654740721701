// polyfill due to Chakra/Framer for iOS (iPhone6s eg.)
if (typeof Object.fromEntries !== 'function') {
  Object.fromEntries = <T extends unknown>(
    entries: Iterable<readonly [PropertyKey, T]>,
  ): { [k: string]: T } => {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        'Object.fromEntries() requires a single iterable argument',
      );
    }

    return [...entries].reduce(
      (obj, [key, val]) => ({
        ...obj,
        [key]: val,
      }),
      {},
    );
  };
}

export default null;
