import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from 'react';
import useSWR, { SWRResponse } from 'swr';
import debounce from 'lodash.debounce';
import fetcher from './fetcher';
import { Cookiebot } from '../types';
import { getStorage, getStorageKey } from './utils.persist';

export function usePersistedState<T>(
  givenKey: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const key = getStorageKey(givenKey);
  const [state, setState] = useState<T>((): T => {
    const item = getStorage().getItem(key);

    if (item) {
      if (item === 'undefined') {
        return defaultValue;
      }
      return JSON.parse(item);
    }
    return defaultValue;
  });
  useEffect(() => {
    if (typeof state !== 'undefined' || getStorage().getItem(key) !== null) {
      getStorage().setItem(key, JSON.stringify(state));
    }
  }, [key, state]);
  return [state, setState];
}

export const useKeyPress = (targetKey: KeyboardEvent['key']) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    },
    [targetKey],
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    },
    [targetKey],
  );

  useEffect(() => {
    window?.addEventListener('keydown', downHandler);
    window?.addEventListener('keyup', upHandler);

    return () => {
      window?.removeEventListener('keydown', downHandler);
      window?.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyPressed;
};

interface WP_ErrorReponse {
  data: {
    status: number;
  };
  status: number;
  message?: string;
}

const isObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === 'object' && !!obj;

const isWpApiErrorResponse = (res: unknown): res is WP_ErrorReponse => {
  if (isObject(res) && isObject(res.data)) {
    return res.data.status === 'number';
  }
  return true;
};

export const useSwrStale = <P extends unknown>(
  endpoint: string | null | false | undefined,
) => {
  const result = useSWR<P, WP_ErrorReponse>(() => endpoint || null, fetcher, {
    revalidateOnFocus: false,
    dedupingInterval: 3600000,
  });

  if (typeof result.data === 'undefined') {
    // Loading
    return {
      data: undefined,
    };
  }

  if (isWpApiErrorResponse(result)) {
    // Thank mr WP Api
    return { ...result, data: [] } as SWRResponse<[], WP_ErrorReponse>;
  }

  return result;
};

export type Dimensions = Pick<
  DOMRect,
  'width' | 'height' | 'top' | 'left' | 'x' | 'y' | 'right' | 'bottom'
>;

const getDimensionObject = (node: Element): Dimensions => {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  };
};

export const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export const useBoundingRect = (
  limit = 100,
): [
  (n: HTMLDivElement | null) => void,
  Dimensions | null,
  HTMLDivElement | null,
] => {
  const isMounted = useIsMounted();
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const ref = useCallback((n) => setNode(n), []);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && node) {
      const measure = () =>
        window.requestAnimationFrame(() => {
          if (isMounted.current) {
            setDimensions(getDimensionObject(node));
          }
        });

      const frameId = measure();

      const listener = debounce(measure, limit || 100);

      window.addEventListener('resize', listener);
      window.addEventListener('scroll', listener);
      return () => {
        window.cancelAnimationFrame(frameId);
        window.removeEventListener('resize', listener);
        window.removeEventListener('scroll', listener);
      };
    }
    return undefined;
  }, [node, limit, isMounted]);

  return [ref, dimensions, node];
};

export const useOnScreen = <T extends Element>(
  ref: RefObject<T>,
  rootMargin = '0px',
): boolean | null => {
  const [isIntersecting, setIntersecting] = useState<boolean | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    const { current } = ref;

    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
      observer.disconnect();
    };
  }, [ref, rootMargin]);
  return isIntersecting;
};

export const useCookieConsent = (reloadAfterDecline = false) => {
  const [consent, setConsent] = useState<Cookiebot['consent'] | undefined>();

  useEffect(() => {
    const onDecline = () => {
      if (window.Cookiebot?.changed && reloadAfterDecline) {
        document.location.reload();
      }
    };
    const onConsentReady = () => {
      setConsent(
        window.Cookiebot?.consent
          ? // need to create new object so react will pick upp changes
            { ...window.Cookiebot.consent }
          : undefined,
      );
    };

    window.addEventListener('CookiebotOnConsentReady', onConsentReady);
    window.addEventListener('CookiebotOnDecline', onDecline);
    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onConsentReady);
      window.removeEventListener('CookiebotOnDecline', onDecline);
    };
  }, []);

  const renewConsent = useCallback(() => {
    try {
      window.Cookiebot?.renew();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return {
    consent,
    renewConsent,
  };
};
