import * as Sentry from '@sentry/nextjs';
import { apiRoute } from './api';

const fetcher = (endpoint: string, headers: Record<string, string> = {}) =>
  fetch(`${apiRoute}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      }
      const errorData = await res.json();
      return errorData?.data || null;
    })
    .catch((err) => {
      /* do nothing for now. */
      console.error('Failed to fetch: ', err);
      Sentry.captureException(err);
      return { error: 'Failed to fetch', description: err.message };
    });

export default fetcher;
