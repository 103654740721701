import { List, ListItem } from '@chakra-ui/react';
import Link from './Link';
import { renderIcon } from './SocialShare';

const SocialMediaLinkList = ({ socialMediaLinks }) => (
  <List
    display="flex"
    gridGap={4}
    justifyContent={['center', null, 'flex-end']}
    flex={['0 0 100%', null, '0 0 auto']}
  >
    {socialMediaLinks?.map((service) => (
      <ListItem key={service.title}>
        <Link href={service.url}>{renderIcon(service.title)}</Link>
      </ListItem>
    ))}
  </List>
);

export default SocialMediaLinkList;
