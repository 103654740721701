import { ComponentStyleConfig } from '@chakra-ui/react';

const List: ComponentStyleConfig = {
  variants: {
    loose: {
      container: {
        pt: 2,
        '> *': {
          // sometimes "loose" list can be a div in case 1 item only, so * is safer than item
          mb: 4,
        },
      },
    },
    searchResults: {
      container: {
        borderRadius: '8px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.15) !important',
      },
      item: {
        '> a': {
          py: 2,
          px: 3,
          display: 'block',
          width: '100%',
          _hover: {
            bg: 'secondaryBackgroundColor',
          },
          _focus: {
            bg: 'secondaryBackgroundColor',
          },
        },
      },
    },
  },
};

export default List;
