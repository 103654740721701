import { baseInputStyle } from './Input';

const Textarea = {
  baseStyle: {
    ...baseInputStyle,
    p: 2,
    maxHeight: '400px',
    minHeight: '100px',
  },
  variants: {
    outline: {
      ...baseInputStyle,
    },
  },
};

export default Textarea;
