import { baseInputStyle, normalSizedInput } from './Input';

const Select = {
  baseStyle: {
    field: {
      ...baseInputStyle,
      cursor: 'pointer',
      ':not([data-filled])': {
        color: 'secondaryColor',
      },
    },
  },
  sizes: {
    md: { field: normalSizedInput },
  },
  variants: {
    outline: {
      field: {
        ...baseInputStyle,
      },
    },
  },
};

export default Select;
