import '../styles/globals.css';
import '../styles/cookiebot.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/archivo-narrow/500.css';
import '@fontsource/archivo-narrow/600.css';
import '../lib/polyfills';
import { Component as ReactComponent, ErrorInfo } from 'react';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { ChakraProvider } from '@chakra-ui/react';
import Layout, { LayoutProps } from '../components/Layout';
import {
  AppProvider,
  UserMetaProvider,
  TranslationsProvider,
} from '../lib/context';
import mhyTheme from '../styles/theme';
import { API_HOST, SEO_ENABLED } from '../lib';
import { createLocale } from '../lib/utils';
import { EntryPageAppProps } from '../types';

const makeLang = (lc: string): string => lc.split('-')[0].toLowerCase();
const makeLangHref = (lc: string, defaultLc?: string): string =>
  !defaultLc || lc === defaultLc ? '/' : `/${makeLang(lc)}`;

interface ExtendedAppProps extends AppProps {
  pageProps: EntryPageAppProps & LayoutProps;
}

const MhyApp = ({
  Component,
  pageProps: {
    subsite = '',
    navigations,
    allSites,
    sites,
    translations,
    metadata,
    isPreview = false,
    isAdminMode = false,
    editLink,
    ...pageProps
  },
  router: { locales, locale, defaultLocale, asPath },
}: ExtendedAppProps) => {
  const currentSite = sites?.find((site) => site.name === subsite);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        {pageProps.data && (
          <>
            {metadata?.description ? (
              <meta name="description" content={metadata.description} />
            ) : null}
            {metadata?.description ? (
              <meta property="og:description" content={metadata.description} />
            ) : null}
            {metadata?.title ? (
              <title>{`${metadata?.title} ${
                currentSite?.blog_id !== 1 ? currentSite?.blogname : ''
              }`}</title>
            ) : null}
            {metadata?.title ? (
              <meta
                property="og:title"
                content={`${metadata?.title} ${
                  currentSite?.blog_id !== 1 ? currentSite?.blogname : ''
                }`}
              />
            ) : null}
            {metadata?.image ? (
              <meta
                property="og:image"
                content={metadata?.image || 'default_image_'}
              />
            ) : null}
            <meta property="og:site_name" content="Mhy.fi" />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`${API_HOST}${
                locale !== 'fi' ? `/${locale}` : ''
              }${asPath}`}
            />
            <link
              rel="canonical"
              href={
                // eslint-disable-next-line no-nested-ternary
                metadata?.canonical
                  ? metadata.canonical.startsWith('http')
                    ? `${metadata.canonical}`.replace(/\/$/, '')
                    : `${API_HOST}${locale !== 'fi' ? `/${locale}` : ''}${
                        metadata.canonical
                      }`.replace(/\/$/, '')
                  : `${API_HOST}${
                      locale !== 'fi' ? `/${locale}` : ''
                    }${asPath}`.replace(/\/$/, '')
              }
            />
          </>
        )}
        {!SEO_ENABLED ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}

        {locales
          ?.filter((loc) => loc !== locale)
          .map((loc: string) => (
            <link
              key={`alt-${loc}`}
              rel="alternate"
              href={makeLangHref(loc, defaultLocale)}
              hrefLang={createLocale(loc).toLowerCase()}
            />
          ))}

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/images/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/images/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/images/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/images/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/images/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/images/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/images/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/images/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/images/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000" /> */}
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <AppProvider
        {...{
          subsite,
          sites: sites || [],
          allSites: allSites || [],
          translations: translations || {},
          navigations,
          breadcrumb: metadata?.breadcrumb || null,
        }}
      >
        <UserMetaProvider>
          <ChakraProvider theme={mhyTheme}>
            <Layout {...{ isPreview, isAdminMode, editLink }}>
              <Component {...pageProps} />
            </Layout>
          </ChakraProvider>
        </UserMetaProvider>
      </AppProvider>
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WKJF5T9');
          `,
        }}
      />
    </>
  );
};

const TranslatedMhyApp = ({ pageProps, ...props }: ExtendedAppProps) => (
  <TranslationsProvider translations={pageProps.translationData}>
    <MhyApp {...props} pageProps={pageProps} />
  </TranslationsProvider>
);

class ErrorBoundary extends ReactComponent<
  ExtendedAppProps,
  { errored: boolean }
> {
  constructor(props: ExtendedAppProps) {
    super(props);
    this.state = { errored: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { errored: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('App caught ', error.name, ' for ', error.message);
    console.error('Error stack - ', error.stack);
    console.error('Component stack: ', errorInfo?.componentStack);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.errored) {
      // You can render any custom fallback UI
      return <h1>Pahoittelut, jotain meni pieleen.</h1>;
    }

    return <TranslatedMhyApp {...this.props} />;
  }
}

export default ErrorBoundary;
