import { ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { Flex } from '@chakra-ui/react';
import HeaderGlobal from './Header/GlobalNav';
import Header from './Header';
import Footer from './Footer';
import Scripts from './Scripts';
import AnnouncementBar from './AnnouncementBar';

const ExitPreview = dynamic(() => import('./ExitPreview'));
const AdminHeader = dynamic(() => import('./WpAdminHeader'), { ssr: false });

export interface LayoutProps {
  isPreview: boolean;
  isAdminMode: boolean;
  editLink: null | string;
  children: ReactNode;
}

const Layout = ({
  children,
  isPreview,
  isAdminMode,
  editLink,
}: LayoutProps) => (
  <>
    <Scripts />
    <Flex minHeight="100vh" flexDirection="column" width="100%">
      {
        /* Disable AdminHeader in dev mode as it's pointless in it and causes double refresh on server side.  */
        process.env.NODE_ENV === 'development' ? null : (
          <AdminHeader isEnabled={isAdminMode} editLink={editLink} />
        )
      }
      <AnnouncementBar />
      <HeaderGlobal />
      <Header />
      <Flex
        as="main"
        flexGrow={1}
        flexShrink={1}
        flexBasis="auto"
        flexDirection="column"
        width="100%"
      >
        {children}
      </Flex>
      <Footer />
      {isPreview && <ExitPreview />}
    </Flex>
  </>
);
export default Layout;
