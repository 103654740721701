import {
  Box,
  Text,
  SimpleGrid,
  List,
  ListItem,
  Container,
  Flex,
} from '@chakra-ui/react';
import { useAppdata, useTranslation } from '../../lib/hooks.context';
import { NavPost } from '../../types';
import Link from '../Link';
import Logo from '../Logo';
import SocialMediaLinkList from '../SocialMediaLinkList';
import FooterLinkList from '../FooterLinkList';
import { safariOnlyCss } from '../../styles/theme';

const year = new Date().getFullYear();
const nbsp = '\xa0';

/*
 * Hmm, mmm... basically invent navigation for footer from the main nav. No single links, but still they are wanted... so
 * put single links into the first item as children because it happens to work then
 */
const makeFooterNav = (nav: NavPost[] = []): NavPost[] =>
  [...(nav || [])]
    .reverse()
    .reduce(
      (acc, item, i) => {
        if (!item.children) {
          // Not likely, but why not
          if (!item.url) {
            return acc;
          }
          return {
            ...acc,
            singles: [...acc.singles, item],
          };
        }
        // Last item, though reversed, so it's the first item. Services
        if (i < nav.length - 1) {
          const { children, ...basicItem } = item;
          // About us basically
          if (children?.every(({ url }) => !url)) {
            return {
              ...acc,
              items: [...acc.items, ...children],
            };
          }
          return {
            ...acc,
            items: [...acc.items, { children, ...basicItem }],
          };
        }

        const { children, ...lastItem } = item;

        if (!children) {
          return {
            singles: [],
            items: [...acc.items, lastItem],
          };
        }

        // Remove third level children as unnecessary and more importantly only return unique urls. Set wasn't any more handy as the items are unique by ID
        const lastChildren = [...children, ...acc.singles].reduce(
          (bacc, { children: _, ...lastChildItem }) =>
            !lastChildItem || bacc.find(({ url }) => url === lastChildItem.url)
              ? bacc
              : [...bacc, lastChildItem],
          [],
        );

        return {
          singles: [],
          items: [
            ...acc.items,
            {
              ...lastItem,
              children: lastChildren,
            },
          ],
        };
      },
      { items: [], singles: [] },
    )
    ?.items.reverse();

const Footer = () => {
  const { navigations } = useAppdata();
  const mainNav = navigations?.main_navigation;
  const footerLinks = navigations?.legal_navigation;
  const socialMediaLinks = navigations?.social_media_links;

  const nav = makeFooterNav(mainNav);
  const t = useTranslation();

  return (
    <Box
      as="footer"
      data-test-id="nav-footer"
      layerStyle="invertedColors"
      px={5}
    >
      <SimpleGrid
        autoRows="2"
        spacing="2"
        as={Container}
        px="0"
        maxW="container.xl"
        py={[10, null, 14]}
      >
        <Flex as="nav" flexWrap={['wrap', 'wrap', 'nowrap']}>
          <Box
            sx={{ 'svg path': { fill: 'currentColor' } }}
            key="footer-logo"
            flexBasis="230px"
            mb="4"
          >
            <Logo />
          </Box>
          <Flex
            gridColumnGap="5"
            gridRowGap="4"
            flexWrap="wrap"
            as={List}
            key="footer-nav-list"
            width="100%"
            sx={safariOnlyCss({ '> *': { mr: 4, mb: 4 } })}
          >
            {nav?.map(({ title, children, url }) => (
              <ListItem key={`footer-item-${url || title}`}>
                <Link href={url} fontWeight="600" mb="1" display="inline-block">
                  {title}
                </Link>
                {children ? (
                  <List key={`list-${url}`}>
                    {children.map(({ title: child, url: childUrl }) => (
                      <ListItem key={childUrl} mb="1">
                        <Link href={childUrl}>{child}</Link>
                      </ListItem>
                    ))}
                  </List>
                ) : null}
              </ListItem>
            ))}
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gridGap="1"
          wrap="wrap"
          pt={10}
        >
          <Text mr={5}>
            {year}
            {nbsp}©{nbsp}
            {t('brand_name')}
          </Text>
          {footerLinks && <FooterLinkList footerLinks={footerLinks} />}
          <SocialMediaLinkList socialMediaLinks={socialMediaLinks} />
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default Footer;
