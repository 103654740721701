import { ComponentStyleConfig } from '@chakra-ui/react';

const Accordion: ComponentStyleConfig = {
  baseStyle: {
    container: {
      _first: {
        pt: 3,
      },
      _notFirst: {
        borderTop: 0,
      },
    },
    icon: {
      color: 'highlightColor',
      fontSize: '1.75em',
    },
  },
  variants: {
    nav: {
      button: {
        pos: 'relative',
        px: 4,
        py: 2,
        justifyContent: 'space-between',
        '.chakra-collapse .chakra-collapse &': {
          pr: 0,
        },
      },
    },
    content: {
      button: {
        fontSize: 'inherit',
        _hover: {
          bg: 'inherit',
        },
      },
      container: {
        '.chakra-collapse': { display: 'block !important' },
        _first: {
          pt: 0,
          borderColor: 'gray2',
        },
        _last: {
          borderBottom: 0,
        },
        _notFirst: {
          borderTop: '4px',
          borderColor: 'gray2',
        },
        borderTop: '4px',
        borderColor: 'gray2',
      },
    },
  },
};

export default Accordion;
