const voidFn = () => {
  /* void */
};

const dummyStorage: Storage = {
  getItem: () => null,
  setItem: voidFn,
  removeItem: voidFn,
  clear: voidFn,
  key: () => null,
  length: 0,
};

const BASE_KEY = 'mhy';

const createStorageKey = (key: string) => `${BASE_KEY}.${key}`;

export const getStorageKey = (key: string) =>
  key.split('.')[0] === BASE_KEY ? key : createStorageKey(key);

export const getStorage = () =>
  typeof sessionStorage !== 'undefined' ? sessionStorage : dummyStorage;