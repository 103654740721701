import { ComponentStyleConfig } from '@chakra-ui/react';
import Input, { baseInputStyle } from './Input';

const { _invalid, ...restBaseInputStyle } = baseInputStyle;

const NumberInput: ComponentStyleConfig = {
  baseStyle: {
    field: {
      ...restBaseInputStyle,
    },
  },
  defaultProps: {
    variant: 'default',
  },
  sizes: Input.sizes,
};

export default NumberInput;
