/*
 * Define plain typo styles here for Chakra components and theme to use
 *
 *
 * Heading styles below
 */

interface TextStyle {
  [cssInJsAttribute: string]:
    | string
    | number
    | null
    | (number | string | null)[];
}

const headingBase: TextStyle = {
  fontWeight: '500',
  lineHeight: '1.15',
};

export const h1: TextStyle = {
  ...headingBase,
  fontWeight: '600',
  fontSize: ['2.5rem', '3rem', '3.5rem'],
};

export const h2: TextStyle = {
  ...headingBase,
  fontSize: ['2rem', '2.5rem', null, '3rem'],
};

export const h3: TextStyle = {
  ...headingBase,
  fontSize: ['1.5rem', '2.5rem'],
};

export const h4: TextStyle = {
  ...headingBase,
  fontSize: ['1.25rem', '1.25rem', '2rem'],
};

export const h5: TextStyle = {
  ...headingBase,
  fontSize: ['1rem', '1.25rem', '1.5rem'],
};

/*
 * ----------------------------------------------------------------------------------------------------
 *
 * Text styles below
 */

export const xLargeText: TextStyle = {
  fontSize: ['1rem', '1.25rem'],
};

export const largeText: TextStyle = {
  fontSize: ['.875rem', '1.125rem'],
  lineHeight: [null, 1.6],
};

export const text: TextStyle = {
  fontSize: ['.8125rem', '1rem'],
  lineHeight: [null, 1.5],
};

export const smallText: TextStyle = {
  fontSize: ['.75rem', '.875rem'],
};

export const xSmallText: TextStyle = {
  fontSize: ['.6875rem ', '.8125rem'],
};

/*
 * ----------------------------------------------------------------------------------------------------
 *
 * Utilities below
 */

/*
 * Use case example: design requires use of 'Mobile heading 4'
 * How to: partialTypo(h4, 0)
 *
 * Use case example: design requires use of 'Desktop heading 4'
 * How to: partialTypo(h4, -1)
 */
export const partialTypo = (textStyle: TextStyle, depth: number) =>
  Object.entries(textStyle).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value)
        ? value.filter((_, i) =>
            depth < 0 ? i >= value.length + depth : i <= depth,
          )
        : value,
    }),
    {},
  );

export const mobileStyle = (textStyle: TextStyle) => partialTypo(textStyle, 0);
export const desktopStyle = (textStyle: TextStyle) =>
  partialTypo(textStyle, -1);
