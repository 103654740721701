import {
  smallText,
  xSmallText,
  text,
  xLargeText,
} from './TypographyDefinitions';

export const searchField = {
  borderRadius: '500px',
  boxShadow: 'inset 0 0 1px 0 var(--mhy-colors-inputBorderColor)',
};

const focusHover = {
  borderColor: 'currentColor',
  transitionTimingFunction: 'ease-out',
  outline: 'none',
  boxShadow: 'none',
};

export const baseInputStyle = {
  color: 'primaryColor',
  borderRadius: '4px',
  fontWeight: '500',
  border: '1.5px solid',
  borderColor: 'inputBorderColor',
  transition: 'color .1s ease-in, border-color .1s ease-in',
  _placeholder: {
    color: 'secondaryColor',
  },
  _invalid: {
    color: 'errorColor',
    borderColor: 'currentColor',
    transitionTimingFunction: 'ease-out',
  },
  _focus: { ...focusHover },
  _hover: {
    ...focusHover,
    transitionDelay: '.25s',
    transitionDuration: '6.5s',
    _focus: { transition: 'none' },
  },
};

export const normalSizedInput = {
  height: ['2.25rem', '2.5rem', '2.75rem'],
  px: 4,
  ...text,
};

const Input = {
  baseStyle: {
    field: baseInputStyle,
  },
  sizes: {
    sm: {},
    normal: {
      field: normalSizedInput,
    },
    md: {
      field: {
        ...xSmallText,
      },
    },
    lg: {
      field: {
        height: ['2.5rem', null, '2.75rem'],
        fontSize: '1rem',
      },
    },
    xl: {
      field: {
        ...smallText,
        height: ['2.5rem', null, '3rem'],
      },
    },
  },
  variants: {
    search: {
      field: searchField,
    },
    siteSelector: {
      field: {
        ...searchField,
        fontSize: ['1rem', null, null, '0.875rem'],
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'default',
  },
};

export default Input;
