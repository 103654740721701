import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useBlog } from '../lib/hooks.context';
import { SiteLocale } from '../types';
import Link, { LinkProps } from './Link';

const MainSiteLogoFi = dynamic(() =>
  import('../public/logos/logo.svg').then(
    ({ ReactComponent }: any) => ReactComponent,
  ),
);

const getMainLogoByLocale = (locale = 'fi') => {
  if (locale === 'fi') {
    return MainSiteLogoFi;
  }

  return dynamic(() =>
    import(`../public/logos/logo-${locale}.svg`).then(
      ({ ReactComponent }: any) => ReactComponent,
    ),
  );
};

const getLogo = (site: null | string, locale = 'fi') => {
  if (!site) {
    return getMainLogoByLocale(locale);
  }

  const logoPrefixLocale = locale === 'fi' ? '' : `-${locale}`;

  try {
    const SiteLogo = dynamic(
      () =>
        import(`../public/logos/logo${logoPrefixLocale}-${site}.svg`)
          .then(({ ReactComponent }) => ReactComponent)
          .catch(() => getMainLogoByLocale('fi')),
      { loading: () => <MainSiteLogoFi /> },
    );
    return SiteLogo;
  } catch (_) {
    return getMainLogoByLocale(locale);
  }
};

const Logo = (
  props: Partial<LinkProps> & { subsite?: string; overrideLocale?: SiteLocale },
) => {
  const { locale: routerLocale } = useRouter();
  const blogname = useBlog(false);
  const { subsite, overrideLocale, ...rest } = props;

  const site = subsite || blogname;
  const locale = overrideLocale || (routerLocale as SiteLocale);
  const SiteLogo = getLogo(site, locale);

  return (
    <Link
      id="mhy-site-title"
      href={`/${site || ''}`}
      maxWidth="175px"
      minWidth="150px"
      mr="1em"
      display="block"
      locale={locale}
      {...rest}
      data-mhy-site-name={(site || 'valtakunnallinen').replace(/\//gi, '')}
    >
      <SiteLogo />
    </Link>
  );
};

export default Logo;
