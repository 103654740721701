import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { MapPin } from '@phosphor-icons/react';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useAppdata, useTranslation } from '../lib/hooks.context';
import { useKeyPress } from '../lib/hooks';
import { CloseIcon } from '../icons';
import { Option } from './AutoSuggest';
import externalSites from '../lib/urls';

const AutoSuggest = dynamic(() => import('./AutoSuggest'));

const SiteSelector = () => {
  const [isActive, setIsActive] = useState(false);
  const { sites, subsite } = useAppdata();
  const { asPath, push } = useRouter();
  const escapePressed = useKeyPress('Escape');
  const t = useTranslation();

  useEffect(() => {
    setIsActive(false);
  }, [asPath, setIsActive]);

  useEffect(() => {
    if (isActive && escapePressed) {
      setIsActive(false);
    }
  }, [escapePressed, isActive]);

  if (!sites) {
    return null;
  }

  const getExternalSite = (name: string) =>
    externalSites.find(
      ({ name: externalSiteName }) => name === externalSiteName,
    );

  const items: Option[] = sites.map(
    ({ blogname: name, path: href, blog_id: id, tags }) => ({
      name,
      href: getExternalSite(name) ? getExternalSite(name)?.href : href,
      id,
      tags,
      target: getExternalSite(name) ? '_blank' : '_self',
    }),
  );

  const currentSiteName = subsite
    ? items.find(({ href }) => href?.replace(/\//g, '') === subsite)?.name
    : '';

  const onOpen = () => {
    setIsActive(true);
  };

  const onClose = () => {
    setIsActive(false);
  };

  const onGoToBySelect = (href: string) => {
    push(href);
  };

  return (
    <Box>
      {!isActive ? (
        <Button
          leftIcon={<MapPin />}
          variant={subsite ? 'ghostGreen' : 'solidGreen'}
          size="sm"
          onClick={onOpen}
        >
          {subsite ? t('association_select_subsite') : t('association_select')}
        </Button>
      ) : (
        <AutoSuggest
          items={items}
          initialItems={items}
          onBlur={onClose}
          onGoTo={onGoToBySelect}
        >
          {({ getOuterProps, getInputProps, openMenu }) => (
            <InputGroup {...getOuterProps()} size="sm">
              <InputLeftElement pointerEvents="none" color="highlightColor">
                <MapPin />
              </InputLeftElement>
              <Input
                variant="siteSelector"
                id="site-selector-input"
                placeholder={
                  currentSiteName || t('search_association_placeholder')
                }
                {...getInputProps({
                  onFocus: openMenu,
                })}
                border="0"
                autoFocus
              />
              <InputRightElement>
                <Button
                  onClick={onClose}
                  variant="transparent"
                  color="secondaryColor"
                >
                  <CloseIcon aria-label={t('close')} size="12px" />
                </Button>
              </InputRightElement>
            </InputGroup>
          )}
        </AutoSuggest>
      )}
    </Box>
  );
};

export default SiteSelector;
