import { useBreakpointValue } from '@chakra-ui/react';
import { getNextBreakpoint } from '../../styles/theme';

export const mobileNavBreakpointKey = 'lg';

export const useIsMobileHeader = () => {
  const isNotMobile = useBreakpointValue({ [mobileNavBreakpointKey]: true });
  return !isNotMobile;
};

export const navNextBreakpointKey = getNextBreakpoint(mobileNavBreakpointKey);

export const arrayShift = <T extends unknown>([, ...shifted]: T[]): T[] =>
  shifted;
