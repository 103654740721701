import { SiteLocale, TranslationType } from '../types';

interface InterpolateArgs {
  fallback?: string;
}

const interpolate = (s: string, args?: InterpolateArgs) =>
  !args || !Object.keys(args).length
    ? s
    : Object.entries(args).reduce(
        (acc, [key, value]) =>
          `${acc.replace(new RegExp(`{{s*${key}s*}}`, 'g'), `${value}`)}`,
        s,
      );

export const getTranslation = ({
  locale,
  defaultLocale,
  key,
  translations,
  args,
}: {
  locale: SiteLocale;
  defaultLocale?: SiteLocale;
  key: string;
  translations: TranslationType;
  args?: InterpolateArgs;
}): string => {
  if (!locale && !defaultLocale) {
    return key;
  }

  const translation = translations?.[key];
  if (translation) {
    return interpolate(translation, args);
  }
  if (args?.fallback) {
    const { fallback, ...remainingArgs } = args;

    const fallbackTranslation = translations?.[fallback];
    if (fallbackTranslation) {
      return interpolate(fallbackTranslation, remainingArgs);
    }
  }

  if (
    typeof translation === 'undefined' &&
    process.env.NODE_ENV === 'development'
  ) {
    (async () => {
      const { addTranslationKey } = await import('./translations.utils');
      addTranslationKey(key, locale);
    })();
  }

  return key;
};

export default { getTranslation };
