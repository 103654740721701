import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  ChakraProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import { CloseIcon } from '../../icons';
import { SearchResult } from '../../types';
import Link from '../Link';
import { mobileNavBreakpointKey, useIsMobileHeader } from './utils';
import { useKeyPress, useSwrStale } from '../../lib/hooks';
import { useBlog, useTranslation } from '../../lib/hooks.context';

// Updates URL for a single query param, if no value is given, the key is deleted.
const updateQueryParam = (
  path: string,
  key: string,
  value: undefined | string = undefined,
): string => {
  const isDelete = typeof value === 'undefined';
  if (!path || (!path.includes('?') && isDelete)) {
    return path;
  }
  const [pathWithoutHash, urlHash] = path.split('#');
  const hash = urlHash ? `#${urlHash}` : '';
  const [url, params] = pathWithoutHash.split('?');

  if (!params?.includes(key) && isDelete) {
    return path;
  }

  if (isDelete) {
    // delete param
    return params.includes('&')
      ? [
          [
            url,
            params
              .split('&')
              .filter((p) => !p.includes(`${key}=`))
              .join('&'),
          ].join('?'),
          hash,
        ].join('')
      : url;
  }
  // update param
  return params?.includes('&')
    ? [
        url,
        params
          .split('&')
          .map((p) => (p.includes(`${key}=`) ? `${key}=${value}` : p))
          .join('&'),
      ].join('?')
    : [[url, `${key}=${value}`].join('?'), hash].join('');
};

const getPopoverCanvasProps = ({ isOpen }: { isOpen: boolean }): ChakraProps =>
  isOpen
    ? {
        position: {
          base: isOpen ? 'fixed' : 'static',
          [mobileNavBreakpointKey]: 'relative',
        },
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bg: { base: 'backgroundColor', [mobileNavBreakpointKey]: 'inherit' },
        px: { base: 3, [mobileNavBreakpointKey]: 0 },
        py: { base: 2, [mobileNavBreakpointKey]: 0 },
        zIndex: 10,
      }
    : {};

const Search = ({ onNavigate }: { onNavigate?: () => void }) => {
  /* TODO:
   * translate, fix icons
   * test performance on production
   */
  const {
    query: { s: searchTerm },
    asPath,
    push,
    replace,
    locale,
  } = useRouter();
  const [{ value, prevValue }, setState] = useState<{
    value?: string;
    prevValue?: string;
  }>({
    value: Array.isArray(searchTerm)
      ? searchTerm.join(' ')
      : searchTerm || undefined,
  });
  const isMobileHeader = useIsMobileHeader();
  const escapePressed = useKeyPress('Escape');
  const blog = useBlog(false);
  const t = useTranslation();

  // Usage of swr is here purely for ease of use and caching.
  // TODO: fix bug when searching from subsite for shared post, then switch to main site and search with same term, the url is wrong because the result is cached to the same endpoint with useMemo in useSwr
  const { data: searchResults } = useSwrStale<SearchResult[]>(
    searchTerm
      ? `/search?s=${searchTerm}${blog ? `&blog=${blog}` : ''}${
          locale ? `&lang=${locale}` : ''
        }`
      : null,
  );

  const isLoading = !!(
    typeof searchResults === 'undefined' && searchTerm !== prevValue
  );

  const isOpen = !!value;
  const onReset = useCallback(() => {
    if (searchTerm) {
      replace(updateQueryParam(asPath, 's'), undefined, { shallow: true });
    }
    if (value) {
      setState((s) => ({ ...s, prevValue: s.value, value: '' }));
    }
  }, [asPath, replace, value, searchTerm]);

  const pushMethod = searchTerm ? replace : push;
  const onUpdate = useCallback(
    debounce((search: string) => {
      if (search && search.length > 2) {
        pushMethod(updateQueryParam(asPath, 's', search), undefined, {
          shallow: true,
        });
      }
    }, 750),
    [pushMethod],
  );

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!e?.relatedTarget) {
      if (!searchTerm) {
        onReset();
      }
    }
  };

  const onChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    // update value right away, but use debounce on updating of router, which will trigger new query
    setState((s) => ({ ...s, prevValue: s.value, value: newValue }));
    if (newValue) {
      onUpdate(newValue);
    } else {
      onReset();
    }
  };

  useEffect(() => {
    if (escapePressed && (value || searchTerm)) {
      onReset();
    }
  }, [escapePressed, value, searchTerm, onReset]);

  return (
    <Popover
      placement={isMobileHeader ? 'bottom-start' : 'bottom-end'}
      closeOnBlur={false}
      isOpen={isOpen}
      autoFocus={false}
      matchWidth
    >
      <Box
        {...getPopoverCanvasProps({ isOpen })}
        sx={{
          '> .chakra-popover__popper': {
            minWidth: '280px !important',
          },
        }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="highlightColor">
            <MagnifyingGlass size="18" />
          </InputLeftElement>
          <PopoverTrigger>
            <Input
              variant="search"
              id="site-search"
              placeholder={t('search_placeholder')}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              pl="10"
              pr="8"
            />
          </PopoverTrigger>
          {searchTerm && (
            <InputRightElement>
              <Button
                onClick={onReset}
                variant="transparent"
                isLoading={isLoading}
              >
                <CloseIcon aria-label={t('search_empty_it')} />
              </Button>
            </InputRightElement>
          )}
        </InputGroup>
        <PopoverContent
          boxShadow="none"
          border="none"
          width="100%"
          maxWidth="500px"
          data-test-id="nav-search-popover"
        >
          {isOpen && (searchTerm || searchResults) && !isLoading ? ( // eslint-disable-line no-nested-ternary
            Array.isArray(searchResults) && searchResults.length ? (
              <List variant={isMobileHeader ? 'loose' : 'searchResults'}>
                {searchResults?.map(
                  ({ post_title: title, ID: postId, url }) => (
                    <ListItem key={postId}>
                      <Link
                        href={url}
                        onClick={() => {
                          onReset();
                          if (onNavigate) {
                            onNavigate();
                          }
                        }}
                      >
                        {title}
                      </Link>
                    </ListItem>
                  ),
                )}
              </List>
            ) : (
              <Text>{t('search_no_results')}</Text>
            )
          ) : null}
        </PopoverContent>
      </Box>
    </Popover>
  );
};

export default Search;
