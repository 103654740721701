import { h1, h2, h3, h4, h5 } from './TypographyDefinitions';

const Heading = {
  baseStyle: {
    wordBreak: 'break-word',
  },
  variants: {
    h1,
    h2,
    h3,
    h4,
    h5,
  },
  defaultProps: {
    size: 'md',
    variant: 'h1',
  },
};

export default Heading;

// import styled from 'styled-components';

// const fontStyles: { [key: number]: string } = {
//   1: "400 3em/1.2 'Arial'",
//   2: "400 2em/1.2 'Arial'",
//   3: "400 1em/1.2 'Times new Roman'",
// };

// export const getHeadingFontBySize = (size: string | number): string => {
//   const index: number = Number.parseInt(`${size}`, 10) || 1;
//   const fontStyle: string | undefined = fontStyles[index];

//   return fontStyle || '100%';
// };

// interface HeadingProps {
//   headingSize?: number | string;
// }

// export default styled.h2`
//   font: ${(p: HeadingProps) => getHeadingFontBySize(p.headingSize || 2)};
//   word-break: break-word;
//   margin-top: 0;
//   &:not(:last-child) {
//     margin-bottom: 0.625em;
//   }
// `;
