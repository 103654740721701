import { Box, Container } from '@chakra-ui/react';
import HeaderNav from './Nav';
import { mobileNavBreakpointKey } from './utils';

const Header = () => (
  <Box
    px={5}
    py={{ base: 3, [mobileNavBreakpointKey]: 10 }}
    as="header"
    position={{ base: 'sticky', [mobileNavBreakpointKey]: 'static' }}
    top={0}
    zIndex={5}
    minHeight="60px"
    bg="backgroundColor"
    borderBottom="1.5px solid"
    borderColor="borderColor"
    data-test-id="nav-header-links"
  >
    <Container as={HeaderNav} maxW="container.xl" variant="noSpacing" />
  </Box>
);

export default Header;
