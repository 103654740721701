// Exception list for external sites, currently SV/Väståboland has it's own external site
const externalSites = [
  {
    name: 'Väståboland',
    href: 'https://abolandsskogsagare.fi/',
  },
  {
    name: 'Länsi-Turunmaa',
    href: 'https://abolandsskogsagare.fi/fi/',
  },
];

export default externalSites;
