import {
  createContext,
  useState,
  FC,
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';
import isEqual from 'lodash.isequal';
import { EntryPageAppProps, Person, TranslationType } from '../types';
import { usePersistedState } from './hooks';

type UseState<T> = [T, Dispatch<SetStateAction<T>>];

type AppState = Pick<
  EntryPageAppProps,
  'subsite' | 'navigations' | 'translations' | 'consenting'
> & {
  sites: NonNullable<EntryPageAppProps['sites']>;
  allSites: NonNullable<EntryPageAppProps['allSites']>;
  breadcrumb?: NonNullable<EntryPageAppProps['metadata']>['breadcrumb'];
};

const appInitialValue: UseState<AppState> = [
  {
    consenting: false,
    breadcrumb: null,
    sites: [],
    allSites: [],
  },
  (s: SetStateAction<AppState>) => s,
];

export const AppContext = createContext(appInitialValue);

export const AppProvider: FC<Partial<AppState>> = ({
  children,
  ...appProps
}) => {
  // TODO: use setPersistent state maybe, probably. it's just a switch of the hook
  const [state, setState] = useState(
    appProps ? { ...appInitialValue[0], ...appProps } : appInitialValue[0],
  );
  const { subsite, translations, breadcrumb, navigations, sites, allSites } =
    appProps;

  useEffect(() => {
    if (state.subsite !== subsite) {
      setState((s) => ({ ...s, subsite }));
    }
  }, [state, subsite]);

  useEffect(() => {
    if (!isEqual(state.translations, translations)) {
      setState((s) => ({ ...s, translations }));
    }
  }, [state, translations]);

  useEffect(() => {
    if (!isEqual(state.breadcrumb, breadcrumb)) {
      setState((s) => ({ ...s, breadcrumb }));
    }
  }, [state, breadcrumb]);

  useEffect(() => {
    if (!isEqual(state.navigations, navigations)) {
      setState((s) => ({ ...s, navigations }));
    }
  }, [state, navigations]);

  useEffect(() => {
    if (!isEqual(state.navigations, navigations)) {
      setState((s) => ({ ...s, navigations }));
    }
  }, [state, navigations]);

  useEffect(() => {
    if (!isEqual(state.sites, sites)) {
      setState((s) => ({ ...s, sites: sites || [] }));
    }
  }, [state, sites]);

  useEffect(() => {
    if (!isEqual(state.allSites, allSites)) {
      setState((s) => ({ ...s, allSites: allSites || [] }));
    }
  }, [state, allSites]);

  return (
    <AppContext.Provider value={[state, setState]}>
      {children}
    </AppContext.Provider>
  );
};

/*
 * USER META
 */

interface UserMetaProps {
  forestExpert?: null | Person;
  selectedService?: null | string;
  membershipSite?: null | string;
}

const userMetaInitialValue: UseState<UserMetaProps> = [
  {},
  (s: SetStateAction<UserMetaProps>) => s,
];

export const UserMetaContext = createContext(userMetaInitialValue);

export const UserMetaProvider: FC = ({ children }) => {
  const [state, setState] = usePersistedState(
    'usermeta',
    userMetaInitialValue[0],
  );

  return (
    <UserMetaContext.Provider value={[state, setState]}>
      {children}
    </UserMetaContext.Provider>
  );
};

export const TranslationsContext = createContext<TranslationType>({});

export const TranslationsProvider: FC<{
  translations?: TranslationType | null;
}> = ({ children, translations }) => (
  <TranslationsContext.Provider value={translations || {}}>
    {children}
  </TranslationsContext.Provider>
);
