import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { useAppdata } from '../lib/hooks.context';
import Link from './Link';
import { AnnouncementBar as AnnouncementBarType } from '../types';

const AnnouncementBar = () => {
  const { navigations } = useAppdata();
  const { title, text, link } =
    navigations?.announcement_bar ||
    ({
      title: '',
      text: '',
      link: null,
    } as AnnouncementBarType);
  return text ? (
    <Box bg="greenSpruce" px={5} data-test-id="nav-header-site-selector">
      <Flex
        as={Container}
        maxW="container.xl"
        py={2}
        justifyContent="center"
        alignItems="center"
        variant="noSpacing"
      >
        <Text textStyle="xs" color="white" textAlign="center">
          {title && (
            <Text as="span" fontWeight="bold">
              {title}
              <Text as="span"> - </Text>
            </Text>
          )}
          {text}
          {link && ' '}
          {link && <Link href={link.url}>{link.title}</Link>}
        </Text>
      </Flex>
    </Box>
  ) : null;
};
export default AnnouncementBar;
