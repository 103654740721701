import { Integrations } from '@sentry/browser';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  autoSessionTracking: false,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || process.env.NODE_ENV,
  integrations: [
    new Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});
