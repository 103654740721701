import Script from 'next/script';
import { useConsenting } from '../lib/consenting';
import CookieConsent from './CookieConsent';

// TODO: enable GA if we want it. we probably do. yes we do
const GA_TRACKING_ID = '';

const GA_SCRIPT =
  GA_TRACKING_ID &&
  `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${GA_TRACKING_ID}', {
    page_path: window.location.pathname,
  });
`;

const MhyScripts = ({ noConsent = false }) => {
  const { consenting } = useConsenting();
  if (!GA_TRACKING_ID) {
    return null;
  }
  // if set on client side, or by default enabled (eg. wp logged in admin) or if cookie is set
  const isScriptsEnabled = consenting || noConsent;
  return (
    <>
      {noConsent ? null : <CookieConsent />}
      {GA_TRACKING_ID && isScriptsEnabled ? (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: GA_SCRIPT,
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default MhyScripts;
