import merge from 'lodash.merge';
import * as Sentry from '@sentry/nextjs';
import { Post, PostMeta, Site, SiteNav } from '../../types';
import { API_HOST } from '..';
import { getData, saveData } from '../dev.cache';

export const apiRoute = `${API_HOST}/wp-json/mhy/v1`;

const basicOptions = apiRoute.includes('mhy_wordpress')
  ? {
      headers: {
        Host: 'localhost',
      },
    }
  : undefined;

const nodeFetch = async <T extends unknown>(
  endpointPath: string,
  useCacheKey: false | string = false,
  options?: Record<string, unknown>,
  returnError = false,
): Promise<T | null | false> => {
  try {
    if (useCacheKey) {
      const cached = (await getData<T>()) || {};
      if (typeof cached[useCacheKey] !== 'undefined') {
        return cached[useCacheKey];
      }
    }
    const url = `${apiRoute}${endpointPath}`;
    const fetchOptions = options ? merge(basicOptions, options) : basicOptions;

    console.info('Fetch from: ', url);

    const res = await fetch(url, fetchOptions);

    if (res.ok) {
      const data = await res.json();
      if (useCacheKey) {
        saveData({ [useCacheKey]: data });
      }
      return data;
    }

    if (returnError) {
      const data = await res.json();
      return data;
    }

    return false;
  } catch (error) {
    console.error(`Error fetching from ${endpointPath} due to: `, error);
    Sentry.captureException(error, {
      extra: { cause: `Error fetching from ${endpointPath}` },
    });
    return null;
  }
};

/* Fetch nonce from a subsite. It's the same nonce as from main site, but skips the super slow main site ajax. */
export const getNonce = async (authCookie: string): Promise<false | string> =>
  fetch(`${API_HOST}/kaakko/wp-admin/admin-ajax.php?action=rest-nonce`, {
    credentials: 'include',
    headers: {
      Cookie: authCookie,
    },
  })
    .then(async (res) => {
      if (res.ok) {
        const nonce = await res.text();
        return nonce || false;
      }
      return false;
    })
    .catch(() => false);

/*
 * ---------------------------------------
 *
 * API Utils
 */

interface CommonApiProps {
  site?: string | false;
  lang?: string;
  blog?: string | false;
}

const resolveParamName = (name: string): string =>
  ({
    site: 'blog',
    site_id: 'blog_id',
  }[name] || name);

// There's probably a better way for it, but at least it works fine. Takes endpoint/string and props. Adds then props to the endpoint as query params unless they're empty.
const addQueryArgs = (
  endpoint: string,
  args: CommonApiProps & Record<string, unknown>,
): string =>
  // trim first and ending slash
  `/${endpoint.replace(/^\/|\/$/g, '')}${
    Object.keys(args).length
      ? `?${Object.entries(args).reduce((acc, [name, value]) => {
          if (name && value) {
            return `${acc && `${acc}&`}${resolveParamName(name)}=${value}`;
          }
          return acc;
        }, '')}`.trim()
      : ''
  }
  `;

/*
 * ---------------------------------------
 *
 * API Functions
 */

export const fetchSubsites = async ({
  lang = 'fi',
}: Pick<CommonApiProps, 'lang'>): Promise<Site[]> => {
  const sites = await nodeFetch<Site[]>(`/sites?lang=${lang}`, `sites-${lang}`);
  return sites || [];
};

export type WP_Post = Post & PostMeta;

export const fetchEntries = async ({
  site = '',
  lang = 'fi',
  blog = '',
}: CommonApiProps): Promise<(Pick<WP_Post, 'url' | 'ID'> | string)[]> => {
  const entries = await nodeFetch<WP_Post[]>(
    addQueryArgs('entries', { site, lang, blog }),
  );
  return entries || [];
};

export interface PreviewData {
  rest_nonce: string;
  mhy_preview_id: string;
  mhy_preview_nonce: string;
  authCookie: string;
}

type AdminModeProps = Pick<PreviewData, 'rest_nonce' | 'authCookie'>;

export const fetchPreviewEntry = async (
  {
    rest_nonce,
    mhy_preview_id,
    mhy_preview_nonce,
    authCookie,
    site = '',
    lang = '',
    site_id = 0,
  }: CommonApiProps & PreviewData & { site_id?: number },
  verifyOnly = false,
): Promise<WP_Post | null> => {
  const endpoint = `${addQueryArgs('preview', {
    site_id,
    site,
    lang,
    mhy_preview_id,
    mhy_preview_nonce,
  })}${verifyOnly ? '&verify_only' : ''}`;

  const post = await nodeFetch<WP_Post>(
    endpoint,
    false,
    {
      credentials: 'include',
      headers: {
        'X-WP-Nonce': rest_nonce,
        Cookie: authCookie,
      },
    },
    true,
  );

  if (!post || !post?.ID) {
    console.error('Fetch preview failed due to: ', post);
  }

  return post && post?.ID ? post : null;
};

export const fetchEntry = async ({
  site = '',
  slug,
  lang = '',
  previewData,
}: CommonApiProps & {
  slug: string;
  previewData?: PreviewData | AdminModeProps;
}): Promise<WP_Post | null> => {
  // If actual preview with nonce
  if (
    previewData &&
    typeof (previewData as PreviewData)?.mhy_preview_nonce === 'string'
  ) {
    const previewEntry = await fetchPreviewEntry({
      ...(previewData as PreviewData),
      site: site as string,
      lang,
    });

    return previewEntry;
  }

  // If circumventing the Next static rendering with preview (basically pure WP without Next and logged in)
  const options = previewData
    ? {
        credentials: 'include',
        headers: {
          Cookie: previewData.authCookie,
          'X-WP-Nonce': previewData.rest_nonce,
        },
      }
    : {
        headers: {
          'MHY-WP-Authorization': process.env.REST_API_PASSWORD,
        },
      };

  const post = await nodeFetch<WP_Post>(
    addQueryArgs('entry', { site, lang, path: slug || 'home' }),
    false,
    options,
  );

  return post || null;
};

export const fetchNavigations = async ({
  site = '',
  lang = '',
  previewData = null,
}: CommonApiProps & {
  previewData: null | AdminModeProps;
}): Promise<SiteNav | null> => {
  const options = previewData
    ? {
        credentials: 'include',
        headers: {
          Cookie: previewData.authCookie,
          'X-WP-Nonce': previewData.rest_nonce,
        },
      }
    : {
        headers: {
          'MHY-WP-Authorization': process.env.REST_API_PASSWORD,
        },
      };

  const navigations = await nodeFetch<SiteNav>(
    addQueryArgs('navigations', { site, lang }),
    previewData
      ? false
      : `navigation${site && `-${site}`}${lang && `-${lang}`}`,
    options,
  );
  return navigations || null;
};

export const sendForm = async <T extends unknown>(
  endpoint: string,
  payload: Record<string, unknown>,
) => {
  const result = await nodeFetch<T>(
    `/form/${endpoint}`,
    false,
    {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    },
    true,
  );

  return result;
};

export const isAdminAllowed = async ({
  authCookie,
  rest_nonce,
}: AdminModeProps): Promise<boolean> => {
  const result = await nodeFetch<boolean>('/admin-allowed', false, {
    credentials: 'include',
    headers: {
      Cookie: authCookie,
      'X-WP-Nonce': rest_nonce,
    },
  });
  return !!result;
};
