export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || 'http://localhost';

export const SEO_ENABLED = () => {
  if (
    API_HOST.includes('staging') ||
    API_HOST.includes('localhost') ||
    API_HOST.includes('motley')
  ) {
    return false;
  }

  return true;
};
